footer, .footer{
    background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/struktur-back-hellrot.svg);
    background-repeat: no-repeat;
    background-position: -10% 0%;
    background-size: 42%;
    a{
        color: $white;
        &:hover{
            color: $white !important;
            text-decoration: underline;
        }
    }
    .btn-link {
        color: $white;
        border: 1px solid $white;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 18px;
        &:hover {
            color: $brand-primary !important;
            background-color: $white;
            border: 1px solid $brand-primary !important;
            text-decoration: none;
        }

    }
    .footer-row{
        min-height: 370px;
    }
    .footer-single-line{
        font-size: 12px;
    }
    padding-bottom: 30px;
}

.back-to-top{
    cursor: pointer;
    position: fixed;
    opacity: .8;
    bottom: 50px;
    right: 20px;
    font-size: 40px;
    background-color: $white;
    line-height: 1;
    z-index: 10000;
}

@media(max-width: 1200px) {
    .footer-social-link {
        font-size: 18px;
    }
}
