$brand: #c13225;
$transparent: transparent !default;
$brand-primary: $brand !default;
$brand-sec: #b02e1e !default;
$brand-secondary: $brand-sec !default;
$blue-default: #628193 !default;
$blue-dark: #284857 !default;
$blue-normal: #3d6275 !default;
$grey-dark: #787877 !default;
$grey-light: #e4e4e5 !default;
$brand-gray-l: $grey-light !default;
$brand-gray-d: $grey-dark !default;
$lime: #b0eb00 !default;
$bright-red: #b30015 !default;
$dark-blue: #2a00b3 !default;
$deep-cerulean: #0077b3 !default;
$bondi-blue: #00b0b3 !default;
$cyan: #24fbff !default;
$heliotrope: #8a7dff !default;
$silver-chalice: #a6a6a6;
$scorpion: #595959 !default;
$tundora: #444 !default;
$mine-shaft: #363636 !default;
$cod-gray: #111 !default;
$silver-white: #F3F4F4 !default;
$white: #fff !default;
$black: #000 !default;
$red: #d9534f !default;
$orange-d: #FF8200 !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green: #5cb85c !default;
$blue: #0275d8 !default;
$teal: #5bc0de !default;
$pink: #ff5b77 !default;
$purple: #613d7c !default;
$verylightgray: #EEEFF0;
// Create grayscale
$gray-dark: #292b2c !default;
$gray: #464a4c !default;
$gray-light: #636c72 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;
$cherry: #930f34 !default;

$carousel-control-color: $grey-dark;

.text-brand-main{
    color: $brand-gray-d !important;
}
a{
    &.text-brand-main{
        &:hover{
            color: $brand !important; 
        }   
    }
}

.text-brand-secondary{
    color: $brand-gray-l !important;
}

a{
    &.text-brand-secondary{
        &:hover{
            color: $brand !important; 
        }   
    }
}


a{
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  &.text-white{
        &:hover{
            color: $gray !important; 
        }   
    }
}
a.text-white.privacy-hint-accept {
    &:hover {
        color: white !important;
    }
}


.text-black{
    color: $black !important;
}

.text-red-l{
    color: $brand !important;
}

.text-red-d{
    color: $brand-secondary !important;
}

.text-blue{
    color:$blue-default !important;
}

.text-blue-dark{
    color: $blue-dark !important;
}

.text-blue-ligth{
    color: $blue-normal !important;    
}



.fill-brand-l{fill:$brand-gray-l;}
.fill-brand-d{fill:$brand-gray-d;}
.fill-brand-complement{fill:$brand-sec;}
.fill-brand-main{fill:$brand;}
.fill-success{fill:$green;}
.fill-danger{fill:$red;}
.fill-blue{fill:$blue;}
.fill-warning{fill:$yellow;}
.fill-light{fill:$gray-lighter;}
.fill-dark{fill:$gray-dark;}
.fill-white{fill:$white;}

.bg-brand-main{
    background-color: $brand !important;
}

.bg-brand-complement{
    background-color: $brand-sec !important;
}

.bg-brand-l{
    background-color: $brand-gray-l !important;
}

.bg-brand-d{
    background-color: $brand-gray-d !important;
}

.bg-blue-brand-d{
    background-color: $blue-dark;
}

.bg-blue-brand{
    background-color: $blue-normal;
}

.bg-blue-brand-l{
    background-color: $blue-default;
}

.border-main{
    border: 8px solid $brand;
}
.border-complement{
    border: 8px solid $brand-sec; 
}
.border-black{
    border: 8px solid $cod-gray;
}


.brownish-d{
    box-shadow: inset 100vw 0 0 0 rgba(166, 153, 145, 0.5);
}

.whitten {
    box-shadow: inset 100vw 0 0 0 rgba(255, 255, 255, 0.5);
}

.darken {
    box-shadow: inset 100vw 0 0 0 rgba(0, 0, 0, 0.5);
}

.reddish {
    box-shadow: inset 100vw 0 0 0 rgba(255, 0, 0, 0.5);
}

.blueish {
    box-shadow: inset 100vw 0 0 0 rgba(0, 125, 255, 0.5);
}

.greenish {
    box-shadow: inset 100vw 0 0 0 rgba(0, 255, 0, 0.5);
}

.yellowish {
    box-shadow: inset 100vw 0 0 0 rgba(255, 255, 0, 0.5);
}

.greenish-grey-l {
    box-shadow: inset 100vw 0 0 0 rgba(110, 133, 150, .5);
}

.greenish-grey-d {
    box-shadow: inset 100vw 0 0 0 rgba(91, 103, 112, .8);
}

.redish-d {
    box-shadow: inset 100vw 0 0 0 rgba(101, 28, 49, .8);
}

.bluish-grey-d {
    box-shadow: inset 100vw 0 0 0 rgba(91, 103, 112, .8)
}
.bluish-green-d {
    box-shadow: inset 100vw 0 0 0 rgba(110, 133, 150, .8)
}


.bg-transparent{
    background-color: transparent !important;
}
