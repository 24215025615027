
.carousel-link-version-1{
  color: $brand-primary;
  background-color: $white;
  border: 1px solid $brand-primary;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: fit-content;
  &:hover{
    color: $white;
    background-color: $brand-primary;
  }
}
.carousel-link-version-2{
  color: $white;
  background-color: $brand-primary;
  border: 1px solid $white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: fit-content;
  &:hover{
    color: $brand-primary;
    background-color:$white;
  }
}
.carousel-indicators{
  &.custom{
    position: relative;
    margin-right: 0;
    margin-left: 0;
    margin-top: 20px;
    li{
      border-radius: 100%;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
    &.custom-2{
      margin-top: 0;
    }
  }
  @media (max-width: 575px) {
    display: none;
  }
}
.prevBtn{
  transform: rotate(180deg);
  @media (max-width: 991px) {
	width: 40px;
	height: 40px;
  }
}

.carousel-caption{
  right: 0;
  left: 0;
  @media (min-width: 1900px){
    right: 12.5%;
    left: 12.5%;
  }
}

.carousel-control-next, .carousel-control-prev{
  background: none;
  width: 54px;
  height: 54px;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 100%;
  }
  background-color: rgba(255, 255, 255, 0.5);
  @media (max-width: 991px) {
	width: 40px;
	height: 40px;
  }
  @media (max-width: 480px) {
    top: 40%;
  }
}
@media (max-width: 575.98px) {
  .carousel-record{
    /* margin-bottom: 300px; */
    .carousel-inner{
      overflow: visible;
      .carousel-caption{
        position: relative;
        /* bottom: -300px; */
		/* top: 264px; */
        background-color: white;
        .redcarouselcaption {
          opacity: 1;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .carousel-record{
	/* margin-bottom: 300px; */
	.carousel-inner{
	  .carousel-caption{
		bottom: auto;
		/* top: 190px; */
		background-color: white;
		.redcarouselcaption {
		  opacity: 1;
		}
	  }
	}
  }
}
.carousel-item {
  .carousel-caption {
    .text {
      font-size: 26px;
      line-height: 50px;
      @media (max-width: 992px) {
        font-size: 20px;
        line-height: 40px;
      }
      span {
        display: inline;
        padding: 0.25rem 0.5rem;
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
        box-decoration-break: clone;
        background: #fff;
        color: $blue-dark;
        &.red {
          background: $brand-primary;
          color: #fff;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .carousel-caption {
      .text {
        font-size: 35px;
        line-height: 68px;
        span {
          display: inline;
          padding: 0.25rem 0.5rem;
        }
      }
    }
  }
  @media (max-width: 575.98px) {
	.w-75 {
      width: 100% !important;
    }
  }
}
