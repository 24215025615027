.flyout-wrapper {
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 2;
  background-color: #ffffff;
  @media (max-width: 768px) {
	top: 100px;
	left: auto;
	right: 0;
  }
  .btn-group {
	img {
	  cursor: pointer;
	  @media (max-width: 768px) {
		height: 30px !important;
		width: 30px !important;
	  }
	}
  }
  .btn-indicator- {
	position: absolute;
	left: 0;
  }
  &.show {
	.btn-indicator- {
	  position: absolute;
	  left: -48px;
	  &:after {
		-moz-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
		-webkit-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
		-o-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
		-ms-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
		filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
		content: '';
		position: absolute;
		left: 0;
		top: 10px;
		width: 0;
		height: 0;
		border-bottom: 20px solid transparent;
		border-top: 20px solid transparent;
		border-left: 15px solid #ffffff;
		border-right: none;
		z-index: 1500;
	  }
	  @media (max-width: 768px) {
		display: none;
	  }
	}
  }
}

.flyout-toggle {
  background-color: $white;
  -webkit-appearance: none !important;
}

.dropdown-toggle {
  &:before {
	display: none !important;
  }
}

.flyout-menu {
  position: relative;
  .btn-indicator-new {
	&.pos-oben {
	  top: 0;
	}
	&.pos-mitte {
	  top: 50px;
	}
	&.pos-unten {
	  top: 100px;
	}

	position: absolute;
	right: -60px;
	width: 60px;
	height: 63px;
	&:after {
	  -moz-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
	  -webkit-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
	  -o-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
	  -ms-filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
	  filter: drop-shadow(2px 0px 0px rgba(0, 0, 0, 0.07));
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 10px;
	  width: 0;
	  height: 0;
	  border-bottom: 20px solid transparent;
	  border-top: 20px solid transparent;
	  border-left: 15px solid #ffffff;
	  border-right: none;
	  z-index: 1500;
	}
	@media (max-width: 768px) {
	  display: none;
	}
  }
  &.show {
	@include transitioning(200ms);
	color: $grey-dark;
	border-right: none !important;
	border: none;
	border-radius: 0;
	min-height: 200px;
	-webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .25);
	-moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .25);
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .25);
	position: fixed !important;
	top: 200px !important;
	left: auto !important;
	right: -452px;
	.h4 {
	  color: $grey-dark;
	}
	@media (max-width: 768px) {
	  transform: translate3d(0, 0, 0) !important;
	  right: 28px;
	  top: 100px !important;
	  min-height: 50vw;
	  min-width: 93vw;
	  width: 70vw !important;
	  .h2 {
		font-size: 20px;
	  }
	  .h4 {
		font-size: 18px;
	  }
	}
  }

}

.formatted-link, .btn-link {
  border: 1px solid $brand-primary;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.btn-link:hover {
  background: $brand-primary;
  text-decoration: none;
  color: white !important;
}

.formatted-link {
  color: $brand-primary;
}

a {
  color: $brand-primary;
}

.icon-list-flyout {
  @media (max-width: 768px) {
	font-size: 13px;
  }
  .linkedin-link-flyout, .twitter-link-flyout {
	max-width: 100% !important;
  }
}
#btn_flyout_map, #btn_flyout_message, #btn_flyout_phone {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  &:hover, &:focus, &:active, &.show {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	z-index: 1000;
  }
}