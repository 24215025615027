.btn-custom{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}
.btn-custom:focus, .btn-custom:hover {
    text-decoration: none;
}

.btn-custom:focus, .btn-custom.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-custom.disabled, .btn:disabled {
    opacity: .65;
}

.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none;
}

a.btn-custom.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}
.tx-indexedsearch-searchbox-button {
    color: #fff;
    background: $brand linear-gradient(180deg,$brand,#A11913) repeat-x;
    border-color: $brand;
    &:hover {
        background: $brand linear-gradient(180deg,#A11913,$brand) repeat-x;
    }
}


.formatted-link {
    display: inline-block;
}
