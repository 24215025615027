
.w-500{
    @media (max-width: 500px){
        width: 100% !important;
    }
}

.w-800{
    @media (max-width: 800px){
        width: 100% !important;
    }
}

.w-870{
    @media (max-width: 870px){
        width: 100% !important;
    }
}

#btn_flyout_map{
    background-color: #ffffff;
}

.dropdown-arrcordions{
    .card-header{
        .chevron-arrcordeon-toogler{
            transform: rotate(-90deg);
            height: auto;
            width: 30px;
            -webkit-backface-visibility: hidden;
        }
        &.collapsed{
            .chevron-arrcordeon-toogler{
                transform: rotate(90deg);
            }
        }
        .text-dark {
            @media (max-width: 768px){
                font-size: 20px;
            }
        }
    }
}

.img-gallery-col{
    border: 2px solid transparent;
}

.galleryModal{
    .close {
        float: right;
        font-size: 5.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .5;
        position: fixed;
        right: 20px;
        z-index: 1060;
    }
    .modal-dialog{
        .close{
            float: right;
            color: #fff;
            position: absolute;
            top: 50px;
            height: 0;
            right: 10px;
            line-height: 0;
            font-size: 8vh;
            background: transparent;
            z-index: 1060;
        }
        height: 100vh;
        max-width: 100vw;
        width: 100vw;
        max-height: 100vh;
        margin: 0;
        .modal-content{
            border-radius: 0;
            height: 100%;
            background-color: rgba(0,0,0,.8);
            border: none;
            @media (max-width: 480px){
                height: 106%;
                width: 106%;
            }
        }
    }
    li{
        border-radius: 100%;
        width: 12px;
        height: 12px;
        cursor: pointer;
    }
}


.very-small-text{
    font-size: 13px;
}


figure.image{
    .image-embed-item{
        width: 100%;
        height: auto;
    }
    @media (max-width: 768px){
        max-width: 450px;
        height: 100%;
        .image-embed-item{
            width: 100%;
            height: auto;
        }
    }
    @media (max-width: 480px){
        max-width: 480px;
        height: 100%;
        .image-embed-item{
            width: 100%;
            height: auto;
        }
    }
}

.img-gallery-col{
    cursor: pointer;
}
.dropdownwithfloatingtetxtNavtabs{
    & ~ .tab-content{
        background-color: $grey-light;
    }
    .nav-link.active.ddwftlink{
        background-color: $grey-light;
        border-color: $grey-light $grey-light $grey-light;
        color: $brand;
    }
    border-bottom: none;
    .nav-link{
        color: $gray;
        font-weight: 700;
        &:hover{
            border-color: transparent;
        }
    }
    .nav-item.activeBefore{
        a{
            position: relative;
            z-index: 2;
            &::after{
                content: '';
                border-left: 3px solid $grey-light;
                border-top: 3px solid $grey-light;
                width: 97%;
                height: 100%;
                position: absolute;
                left: -1px;
                top: -1px;
                z-index: 1;
            }
        }
    }
    .nav-item.activeAfter{
        a{
            position: relative;
            z-index: 2;
            &::after{
                content: '';
                border-right: 3px solid $grey-light;
                border-top: 3px solid $grey-light;
                width: 97%;
                height: 100%;
                position: absolute;
                right: -1px;
                top: -1px;
                z-index: 1;
            }
        }
    }
}

.text-col-2{
    @media (min-width: 769px){
        -webkit-column-width: 50%;
        column-width: 50%;
        column-count: 2;
        column-gap: 40px;
    }
}

#privacy-hint{
    left: auto;
    @media (max-width: 480px){
        /* height: 35vw !important; */
    }
}

@media (max-width: 992px) {
    .col-media {
        .image {
            margin-bottom: 30px;
        }
    }
}

.heading-headline{

}
.heading-headline-red{
    margin-bottom: 38px!important;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.redcarouselcaption{
    @media (max-width: 993px){
        .h3{
            font-size: 16px !important;
        }
        opacity: .8;
    }
    &.carousel-indicators{
        @media (max-width: 993px){
            top: 0;
        }
    }
}
.h-md-400{
    @media (min-width: 768px){
        height: 400px !important;
    }
}
.partner-carousel{
    //min-height: 30vh;
}
.partner-inner{
    //min-height: 40vh;
}
.py-6px {
    padding-top: 6px;
    padding-bottom: 6px;
}
@media (min-width: 481px) and (max-width: 991px) {
    .container{
        padding-left: 45px !important;
        padding-right: 45px !important;
        max-width: 100%;
    }
}
@media (min-width: 992px) and (max-width: 1360px) {
    .container{
        padding-left: 55px !important;
        padding-right: 55px !important;
    }
}

//so von bootstrap documentation übernommen

@media (max-width: 480px) {
    .h4{
        margin-top: 20px;
        font-size: 18px;
    }
}
@media (max-width: 575.98px) {
    .h4{
        margin-top: 20px;
        font-size: 18px;
    }
}

.rte-text-red{
    width: fit-content;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $brand;
    color: $white;
}
