html, body {
    height: 100%;
    font-family: $font-family;
    font-weight: $font-normal;
    @media (max-width: 992px) {
        font-size: 14px;
    }
    font-size: 16px;
    color: #737B82;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body{
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5{
    hyphens: auto;
}

.hyphens{
    hyphens: auto;
    word-break: keep-all;
    white-space: pre-wrap;
}

.text-bold{
    font-weight: $font-bold;
}

.text-thin{
    font-weight: $font-thin;
}

h1, .h1{
    font-size: 40px;
    @media (max-width: 992px){
        font-size: 30px !important;
    }
    @media (max-width: 768px){
        font-size: 25px !important;
    }
}
h2, .h2{
    font-size: 26px;
    @media (max-width: 992px){
        font-size: 23px !important;
    }
    @media (max-width: 768px){
        font-size: 18px !important;
    }
}

h3, .h3{
    font-size: 24px;
    @media (max-width: 992px){
        font-size: 20px !important;
    }
    @media (max-width: 768px){
        font-size: 18px !important;
    }
}

p{
    font-weight: $font-thin;
}


title, .title{
    font-size: 36px;
    font-weight: $font-thin;
    color: #ffffff;
    background-color: $brand-primary;
}

.extbase-debugger.extbase-debugger-floating{
    overflow-y: auto;
    min-height: fit-content;
    z-index: 2000;
}
.extbase-debugger {
  overflow: visible !important;
  margin-top: 200px;
}

.container-main {
    flex: 1 0 auto;
    background: white;
}

.hidden{
    display: none;
}

@media (max-width: 992px) {
    .hideonmobile {
        display: none !important
    }
}

.cursor-pointer{
    cursor: pointer;
}
a{
    color: $brand;
    &:hover {
       color: $secondary;
    }
    &:not([class]){
        &:hover{
            text-decoration: underline;
        }
    }
    &:hover{
        text-decoration: none;
    }
}
b, strong, .bold{
    font-family: $font-family;
    font-weight: $font-bold;
}

.align-center{
    text-align: center;
}
*::-moz-selection { 
    color: #fff;
    background-color: $brand;
}

*::selection {
    color: #fff;
    background-color: $brand;
}


.btn{
    border-radius: 0 !important;
    &:focus{
        box-shadow: none !important;
    }
}
a.nav-link {
    &:focus {
        outline: 0;
    }
}
*:focus {
    outline: 0;
}
a.text-white.privacy-hint-accept {
    &:hover {
        color: white !important;
    }
}
.p-checko {
    a:not(.formatted-link) {
        text-decoration: underline;
    }
    a:not(.phone-link) {
        text-decoration: underline;
    }
    a:not(.email-link) {
        text-decoration: underline;
    }
}

.error { 
    color: #ff5b5b 
}
.has-error input { 
    border: 1px solid #ff5b5b; 
}

picture{
    width: 100%;
}

/*Used the img class img-responsive for imgaes here because in b4 the img class is img-fluid*/
.img-responsive{
    max-width: 1600px;
    width: 100%;
    height: auto;
}

.imgLeft{
    &.imgTop{
        float: left;
        margin-right: 20px;
    }
    &.imgBottom{
        margin-right: 20px;
        float: left;
        clear: left;
    }
}
.imgRight{
    &.imgTop{
        float: right;
        margin-left: 20px;
        margin-right: -15px;
        @media (max-width: 991px) {
            float: none;
            margin-left: 0;
            margin-bottom: 20px;
            margin-right: 0;
        }
    }
    &.imgBottom{
        margin-left: 20px;
        float: right;
        clear: right;
    }
}
.img-float-bottom-r{
    .spacer-right {
        width: 0px;
        float: right;
    }
}

.img-float-bottom-l{
    .spacer-left {
        width: 0px;
        float: left;
    }
}
.carousel{
    // cursor: ew-resize;
}

tr{
    td{
        padding-right: 5px;
        padding-left: 5px;
    }
    td:first-child{
        &.text-justify{
            padding-left: 0;
            padding-right: 10px;
        }
    }
    td:last-child{
        &.text-justify{
            padding-right: 0;
            padding-left: 10px;
        }
    }
}

.navbar-brand{
    z-index: 1100;
}

.form-control{
    border-radius: 0 !important;
}

.mb-120 {
    margin-bottom: 120px;
}


