@import "./custom/mixins.scss";
@import "./components.scss";



$brand-white:   #fff;
$brand-gray: #888B8D;
$brand-secondary: #4B4B4B !default;

// $brand-primary: #FF8200;

$screen-xs:480px;
$screen-xs-min:$screen-xs;
$screen-phone:$screen-xs-min;
$screen-sm:768px;
$screen-sm-min:$screen-sm;
$screen-tablet:$screen-sm-min;

$screen-md:1004px;
$screen-md-min: ($screen-md + 1);
$screen-desktop: $screen-md-min;

$screen-lg: $screen-md;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$grid-float-breakpoint:     $screen-sm-min !default;
$grid-gutter-width-double:  60px !default;


// Gray colors
$body-color:   #5c666f;
$gray-base:    #5c666f;

// text
$text-color: #5c666f;

// Typography
$font-family-sans-serif:  "Open Sans","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif-medium:  "Open Sans","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base:        $font-family-sans-serif;

// For h1-h6
$headings-font-family: $font-family-sans-serif-medium;


// text overrides
$body-color: #888B8D;
$font-size-base: 15px;
$font-family-base: $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif;
$text-color: #6C6F73;
$headings-color: #2D3238;
$line-height-base: 1.4;


// gray override
$gray-light: #888B8D;
$text-muted: $gray-light;


// h3 override
$font-size-h3: 28px;


// nav tranpsarent
$navbar-transparent-brand-hover-bg:       transparent;
$navbar-transparent-toggler-hover-bg:     transparent;
$navbar-transparent-toggler-border-color: transparent;


// nav bordered
$nav-bordered-color:              #000;
$nav-bordered-color-active:       $brand-primary;
$nav-bordered-font-weight:        300;
$nav-bordered-font-weight-active: 700;

$nav-link-padding: 10px 0 !default;
$nav-link-hover-bg: $brand-primary !default;
$navbar-default-border: none !default;


// misc typography
$headings-font-weight: 400;
$btn-font-weight:      400;
$letter-spacing-base:  0;


// lead
$lead-letter-spacing: $letter-spacing-base;
$lead-font-size:      $font-size-base * 1.5;


// blocks
$block-title-font-size-sm: $font-size-base * 2.8;
$block-title-font-size:    $font-size-base * 2.5;
$block-lead-font-size-sm:  $font-size-base * 1.4;
$block-vertical-padding:   60px;



$spacer:   60px !default;


html.sr .tx-teclocations .company-listing .company-item {
  visibility: hidden;
}


