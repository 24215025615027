.company-item{
    opacity: 1 !important;
    background-color: $white;
    border: 1px solid transparent;
    .card-header{
        cursor: pointer;
        padding: 10px;
        background-color: $grey-light;
        border: 1px solid transparent;
        min-height: 130px;
        .company-item-title{
            min-height: 20px;
        }
    }
    .card-body {
        background-color: $grey-light;
        border: 1px solid transparent;
        padding: .75rem 1.25rem;
    }
    .company-item-action{
        bottom: 5px;
        right: 5px;
    }
    &:hover{
        .card-header {
            background-color: $white;
            @include transition(border-bottom 1s ease 0s);
            @include transition(border 1s ease 0s);
            @include transition(background-color 1s ease 0s);
            border: 1px solid $grey-light;
            border-bottom: 1px solid transparent;
        }
        .card-body {
            background-color: $white;
            @include transition(border-top 1s ease 0s);
            @include transition(border 1s ease 0s);
            @include transition(background-color 1s ease 0s);
            border: 1px solid $grey-light;
            border-top: 1px solid transparent;
        }
    }
    .company-item-title.text-bold {
        height: auto !important;
    }
    .btn.btn-link {
        position: absolute;
        bottom: 10px;
        right: 36px;
        background-color: white;
        &:hover {
            background: $brand-primary;
        }
    }
    .company-item-url {
        padding: 0;
        border: 0;
        display: block;
        background-color: transparent;
        cursor: pointer;
    }
    visibility: hidden;
}
.tt-dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
    background-color: $white;
}
.tt-suggestion{
    background-color: $white;
}
.twitter-typeahead{
    width: 100%;
}
.company-filter{
    .form-control{
        border-radius: 0 !important;
        content{
            border-radius: 0 !important;
        }
        option{
            border-radius: 0 !important;
        }
    }
}
.teclocations-detail {
    margin-top: -40px;
    @media (max-width: 768px) {
        margin-top: -10px;
    }
    .table {
        max-width: 450px;
    }
    .formatted-link.chevron-link  .fa-chevron-right {
        transform: rotate(180deg);
    }
}


.tx-mapmaker {
    .filter {
        background-color: transparent !important;
        border: none !important;
        float: none !important;
        height: auto !important;
        margin: 0 !important;
        padding: 0 15px !important;
        width: auto !important;
        position: relative;

        select {
            width: 100%;
            font-size: 12px;
            padding: 3px;
        }

        label {
            display: inline-block;
            margin-right: 10px;
        }
    }

    .clearer {
        clear: both;
    }
    .mara-buttons {
        button {
            cursor: pointer;
        }
    }
}
#tx-mapmaker-name-suggest button.reset-search {
    cursor: pointer;
}
#tx-mapmaker-filterform {
    select {
        cursor: pointer;
    }
}
.detail-template {
    .btn.btn-success {
        @extend .formatted-link;
        @extend .chevron-link;
        background: white !important;
    }
}

#map {
    .gm-style {
        .cluster {
            div {
                font-size: 30px !important;
                color: white !important;
            }
        }
    }
}
