.gcprivacy-no-map {
  display: block;
  width: 100%;
  height: 300px;
  background-image: url('/typo3conf/ext/greiner_core/Resources/Public/images/cookie/googlemaps.png');
  background-size: cover;
  padding: 10%;
  text-align: center;
  .gcprivacy-no-map--container {
    margin: 0 auto;
  }
}



