$container-max-widths-special: (
sm: 100%,
md: 100%,
lg: 100%,
xl: 1944px
) !default;




@mixin make-container-max-widths-special($max-widths: $container-max-widths-special, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width-special in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width-special;
        }
    }
}

.container-special {
    @include make-container();
    @include make-container-max-widths-special();
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    @media (min-width: 1900px){
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

.container{
    @media (min-width: 1900px){
        padding-left: 84px;
        padding-right: 84px;
    }

}


.container{
    @media (min-width: 1400px){
        padding-left: 84px;
        padding-right: 84px;
        max-width: 1280px;
    }

}