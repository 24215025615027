.tx-indexedsearch-text-item-path {
  a {
    padding: 0;
  }
}

.tx-indexedsearch-redMarkup {
  background-color: $brand;
  color: $white;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.tx-indexedsearch-searchbox-button {
  @extend .btn-primary;
  cursor: pointer;
  max-width: 400px;
  width: 150px !important;
}

.tx-indexedsearch-searchbox-sword {
  max-width: 400px;
}

.tx-indexedsearch-res {
  &.card {
    margin-bottom: 0;

    .card-header {
      background-color: rgba(0, 0, 0, .04);
      padding-top: 28px;
      padding-bottom: 0;
    }

    .card-body {
      padding: .75rem 1.25rem;
    }

    .card-footer {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
}

.tx-indexedsearch-browsebox ul {
  padding-left: 0;
  list-style: none;

  li {
    list-style: none;
    display: inline-block;
    &:before {
      content: ''
    }

    a {
      padding: 3px 9px;
      border-radius: 0 !important;
      color: #787877;
      background-color: transparent;
      background-image: none;
      border: 1px solid #787877;
    }
  }

  li:not(:last-child) {
    margin-right: .5rem;
  }
}
