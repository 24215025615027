.ap-nopadding .col-media {
  padding-left: 0;
}

.form-check {
  padding-left: 0;
  label span {
    padding-left: 10px;
  }
}
.control-label {
  display: none;
}
.form-group {
  margin-top: 1rem;
}