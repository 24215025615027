html {
  scroll-behavior: smooth !important;
}

body.bdcoe, body.bdc, body.hub103, body.lab22, body.tp40-years {
  .container-main {
    //#main_nav_main_container.nav-scrolled #logoBrand {
    //  position: relative;
    //  top: 0;
    //  margin-top: 0;
    //}
    // petrol: 586c7d
    .credits-txt {
      max-width: 400px;
      font-size: 12px;
    }

    #main_nav_main_container #nav_row_snd_cred {
      transition: margin .4s;
    }

    #main_nav_main_container.nav-scrolled #nav_row_snd_cred {
      margin-top: 12px !important;
    }

    #logo {
      max-width: 220px;
      transition: width .4s;
      //margin-top: -17px;
      height: auto;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform;
      transition-property: transform;

      &:hover, &:focus, &:active {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }

    .carousel-item .carousel-caption .text span {
      padding-left: 1.1rem;
    }

    .ce-bullets li {
      border-top: 1px solid #0c5460;
      padding: 10px 0 10px 11px;
      margin-left: -10px;

      &:last-child {
        border-bottom: 1px solid #0c5460;
      }
    }

    .card {
      border-radius: 0;
      border: 0;

      .card-header {
        background: #586c7d;
        color: white;
        border-radius: 0;
        border: 0;
        padding: 8px 12px;
      }

      .card-body {
        padding: 12px 0 0 0;
        background: white;
      }

      .list-group {
        padding-left: 0;
        border-left: 0;
        border-right: 0;

        .list-group-item {
          padding: 5px 12px;
          border-radius: 0;
          border-left: 0;
          border-right: 0;
          border-top: 1px solid #586c7d;
          z-index: 1;
          border-bottom: 0;

          &:last-child {
            border-bottom: 1px solid #586c7d;
          }

          &:before {
            content: '';
            padding-right: 0;
            margin-left: 0;
          }
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .bg-blue-brand {
      hr.ce-div {
        border-top: 1px solid white;
      }
    }

    @media (min-width: 992px) {
      .carousel-item .carousel-caption .text {
        font-size: 30px;
        line-height: 65px;
      }
    }
    @media (min-width: 1200px) {
      .carousel-item .carousel-caption .text {
        font-size: 35px;
        line-height: 70px;
      }
    }

    .case-1 {
      .carousel-item {
        height: 45vw !important;
        @media (max-width: 575px) {
          //height: 100vw!important
        }
      }

      .carousel-caption {
        bottom: 19%;
        @media (min-width: 92px) and (max-width: 692px) {
          bottom: 0;
          display: none !important;
        }
      }
    }

    .wrapper-section-case-1 {
      color: #6f6f6e;

      a, h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        color: #0f5c6e;

        strong {
          color: #0f5c6e;
        }
      }

      h1 {
        text-transform: uppercase;
      }

      a.formatted-link {
        color: #c13225;

        &:hover {
          color: white;
        }
      }
    }

    .btn-primary, .formatted-link {
      @extend .carousel-link-version-1;
    }

    .wrapper-section-case-2 {
      p.bg-brand-d {
        background-color: white !important;
      }
    }

    .form-control {
      background-color: transparent;
      border: 1px solid white;
      color: white;

      &:focus {
        outline: none !important;
        border-color: #white;
        box-shadow: 0 0 5px white;
      }
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    ::placeholder {
      color: #ffffff !important;
    }

    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: #ffffff !important;
      opacity: 1;
    }

  }
}

body.bdc .container-main {

  .wrapper-section-case-1,
  .wrapper-section-case-2,
  .wrapper-section-case-3,
  .wrapper-section-case-4 {

    &.svg-angle-top {
      background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/backangle-reverse-top.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }

    &.svg-angle-bottom {
      background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/backangle-reverse-bottom.svg);
      background-repeat: no-repeat;
      background-position: 0 100%;
      background-size: 100%;
    }
  }

  .bg-brand-l {
    background-color: #eff0f3 !important;
  }

  .h1 {
    font-size: 60px;
    @media only screen and (max-width: 768px) {
      font-size: 40px;
    }
  }

  .h2 {
    font-size: 30px;
    font-weight: 700;
  }

  p {
    font-weight: 400;
    font-size: 18px;
  }

  a.formatted-link {
    padding: 10px;
    color: #586c7d !important;
    border: 1px solid #586c7d;

    &:hover {
      background-color: #0f5c6e !important;
      color: white !important;
    }
  }

  .finances {
    .row.justify-content-center {
      margin: 0;
    }

    @media only screen and (min-width: 1000px) {
      .row.justify-content-center {
        padding-left: 150px;
        padding-right: 150px;
      }
    }
  }

  .container {
    max-width: 1500px;
  }

  .card-body .list-group {
    border-top: 1px solid !important;
  }

  .ce-bullets li {
    border-top: 2px solid #0c5460;
  }

  .ce-bullets li:last-child {
    border-bottom: 2px solid #0c5460 !important;
  }

  .mono_bdc_header {
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    justify-content: center;
    margin-bottom: 40px;
    @media (min-width: 600px) {
      justify-content: start;
      height: 100vh;
    }


    .formatted-link {
      padding: 10px;
      color: #586c7d !important;
      border: 2px solid #586c7d;
    }

    &__text {
      padding: 30px 25px;
      font-size: 30px;

      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 50px;
        width: 210px;
        justify-content: left;
        display: flex;
      }

      @media (min-width: 600px) {
        padding: 0;
        border: none;
        background: none;

      }

      .link-button {
        display: flex !important;
        justify-content: center !important;
      }

      a {
        font-size: 20px;
      }

      h1 {
        @media only screen and (max-width: 1300px) {
          font-size: 26px;
        }
      }
    }

    &__wrapper {
      background: #fffc;
      border: 2px solid;
      width: 100%;
      margin: 0 45px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 600px) {
        margin-left: 50px;
        width: 50%;
        align-items: flex-start;
        background: none;
        border: none;
        padding: 0;
      }
      @media (min-width: 1050px) {
        margin-left: 100px;
      }
    }

    &__headline {
      width: 100%;
      padding: 30px 25px;
      @media only screen and (min-width: 600px) {
        width: 70%;
        padding: 0;
      }

      p {
        font-size: 18px;
        @media only screen and (max-width: 1000px) {
          font-size: 16px;
        }
      }
    }

    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (min-width: 600px) {
        width: 70%;
        margin-top: 70px;
        justify-content: flex-start;
      }
    }

    &__logo {
      height: 25vh;
      display: flex;
      justify-content: center;
      margin-top: 75px;
      width: 100%;
      @media (min-width: 600px) {
        justify-content: start;
        margin-left: 100px;
      }
    }
  }

  .formatted-link {
    padding: 10px;
    color: #586c7d !important;
    border: 2px solid #586c7d;
  }

  @media (min-width: 992px) {
    .col {
      .textpicBodyTextLeft {
        margin-top: 80px;
        padding-right: 100px;
      }
    }

    .col {
      .textpicBodyTextRight {
        padding-left: 100px;
      }
    }
  }

  .contact {
    p {
      color: #284857;
    }

    .col-md-6 {
      display: flex;
      align-items: end;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    .magnific-gallery {
      .image {
        margin: 0 !important;
      }
    }
  }

}

body.hub103 {
  height: 100%;
  max-width: 1400px;
  display: flex;
  margin: 0 auto;

  a, h1, .h1, h2, .h2, h3, .h3, h4, .h4, p {
    color: black !important;
    font-family: Urbanist, Arial, sans-serif;
  }

  .h1 {
    font-size: 45px !important;
    @media only screen and (min-width: 850px) {
      font-size: 70px !important;
    }
  }

  .h2 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    font-weight: 500;
    font-size: 22px;
  }

  #privacy-spacer {
    background: #f4eeee !important;
  }

  .container {
    padding: 0 !important;

    &-main {
      background: transparent;
    }
  }

  .bg-brand-l {
    background-color: #f4eeee !important;
  }

  .mono_bdc_header {
    display: flex;
    align-items: flex-start;

    background-color: white;
    background-size: cover;
    height: auto;
    background-position: top;
    flex-direction: column;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 100;
    margin-bottom: 70px;
    padding-bottom: 120px;

    @media (min-width: 717px) {
      padding-bottom: 80px;
      margin-bottom: 30px;
    }
    @media (max-width: 980px) {
      padding-bottom: 0;
    }
    @media (min-width: 1100px) {
      align-content: baseline;
    }

    .formatted-link {
      padding: 10px;
      color: #586c7d !important;
      border: 2px solid #586c7d;
    }

    &__text {
      p {
        font-size: 30px !important;
      }

      color: black;

      a {
        font-size: 20px;
      }

      .formatted-link {
        background-color: transparent;
        border: 2px solid black;
        color: black !important;
        font-size: 25px;

        &:hover {
          background: black;
          color: white !important
        }
      }
    }

    &__wrapper {
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fffc;
      border: 2px solid;
      padding: 10px;
      width: 80%;

      @media only screen and (min-width: 1100px) {
        margin-left: 3%;
        width: 50%;
        display: flex;
        border: none;
        background: transparent;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__headline {
      @media only screen and (min-width: 1100px) {
        width: 90%;
        p {
          font-size: 60px;
        }
      }
      width: 100%;
      font-size: 70px;
      color: black;
    }

    &__container {
      @media (min-width: 1100px) {
        width: 80%;
      }
      width: 100%;
    }

    &__logo {
      height: fit-content;
      display: flex;
      justify-content: start;
      margin-bottom: 40px;
      margin-left: 3%;
      @media (max-width: 850px) {
        width: 80%;
      }

      img {
        width: 50vw;
        padding: 20px;
        @media (min-width: 850px) {
          max-width: 500px;
          padding: 0;
        }
      }
    }

  }

  .mono_hubsecond {
    position: relative;
    width: 100%;
    z-index: 1;
    padding-bottom: 200px;
    @media (min-width: 1100px) {
      padding-bottom: 10px;
    }
    @media (max-width: 850px) {
      padding-bottom: 50px;
    }

    &_header {
      &__logo {
        display: flex;
        width: 80%;
        justify-content: center;
        @media (min-width: 1100px) {
          width: 80%;
        }
      }
    }

    .mono_bdc_header {
      &__wrapper {
        margin-left: 0;
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: end;
        border: none !important;
        background: transparent;
        margin-top: 500px;
        //padding-right: 60px;
        @media (max-width: 1140px) {
          margin-top: 150px;
        }
        @media (max-width: 1099px) {
          margin-top: 300px;
        }
        @media (max-width: 992px) {
          margin-top: 170px;
        }
        @media (max-width: 768px) {
          margin-top: 30px;
          background: #fffc;
        }
      }

      &__container {
        text-align: center;
        width: 50%;
        @media (max-width: 850px) {
          width: 100%;
        }
      }

      &__headline {
        text-align: center;
        width: 50%;
        font-size: 60px;
        @media (max-width: 850px) {
          width: 100%;
          font-size: 40px;
        }
      }

      &__text p {
        font-size: 25px !important;
      }
    }
  }


  .row.card {
    border: none;
    margin: 0;

    .row.justify-content-center.mb-1 {
      margin: 0;
    }

    @media (min-width: 850px) {
      flex-direction: initial !important;
      //justify-content: space-evenly;
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    .col-md-4 {
      //max-width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      @media (min-width: 850px) {
        max-width: 30%;
      }
    }

    .col {
      &.textpicBodyTextCenter {
        background: #f4eeee;
        color: black;
        padding: 20px;
        padding-top: 80px;

        h3, .h2, p {
          text-align: center;
        }
      }
    }

    .magnific-gallery {
      .image {
        width: 100px;
      }

      display: flex;
      justify-content: center;
      position: relative;
      top: 80px;
      z-index: 1000;
    }
  }

  .contact {
    .col-md-6 {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
      @media only screen and (min-width: 767px) {
        padding-left: 0;
      }
    }

    p {
      color: black;
      font-size: 20px;

    }
  }

  /*
    .formatted-link {
      padding: 20px;
      color: black !important;
      border: 2px solid black;
      font-weight: bold;
      font-size: 20px;
      text-decoration: underline;

      &:hover {
        background: #f4eeee;
        color: black !important;
        border: 2px solid black;
      }
    }
  */
  .imageoverlay {
    margin: 0;
    padding: 0;

    .col-md-12 {
      padding: 0;
    }

    .row.justify-content-center {
      margin: 0;
      padding: 0;

      .row.justify-content-center {
        margin: 0;
        padding: 0;
      }
    }

    figure.image {
      max-width: 100% !important;
    }

    .magnific-gallery, .px-15 {
      padding: 0 !important;
    }

    .row.no-gutters .col.textpicBodyTextCenter {
      top: -135px;
      @media only screen and (min-width: 850px) {
        top: -220px;
      }
    }
  }

  .row.ap-nopadding.contact {
    margin: 0;
  }


  .textpicBodyTextCenter {
    .formatted-link {
      background-color: rgba(255, 255, 255, 0.3);
      border: 2px solid white;
      color: white !important;
      font-size: 25px;
      padding: 18px 15px;

      &:hover {
        background-color: black;
        border: 2px solid black;
      }
    }
  }

  .textpicBodyTextRight {
    .formatted-link {
      background-color: rgba(255, 255, 255, 0.3);
      border: 2px solid black;
      color: black !important;
      font-size: 18px;
      padding: 12px 8px;

      &:hover {
        background-color: black;
        border: 2px solid black;
        color: white !important;
      }
    }
  }
}

body.lab22 {

  @media (max-width: 769px) {
    h2, .h2 {
      font-size: 20px !important;
    }
  }

  .container-main {
    max-width: 2000px;
  }

  ul {
    font-weight: 300;
  }

  ::selection {
    background: #a4d8e0;

  }

  .bg-blue {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #a4d8e0 !important;
    display: flex;
    justify-content: center;

    padding-left: 25px !important;
    padding-right: 25px !important;

    @media (min-width: 768px) {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }

    p, h2 {
      color: #294065;
    }

    .magnific-gallery {
      display: flex;
      justify-content: center;
    }


    .row {
      &.no-gutters {
        max-width: 1140px;
        color: white;
        width: 100%;
      }
    }

    h2 {
      font-size: 38px;
      font-weight: bold !important;

      .h3 {
        font-size: 28px;
        font-weight: bold;
      }
    }

  }

  .bg-white {

    a, h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
      color: #294065 !important;

      strong {
        color: #294065 !important;
      }
    }

    .container {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    @media (min-width: 1100px) {
      .container {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }

    @media (min-width: 1200px) {
      .container {
        padding-left: 55px !important;
        padding-right: 55px !important;
      }
    }


    @media (min-width: 768px) {
      .bg-transparent {
        margin-top: 50px;
      }

      .bg-blue-dark {
        margin-top: 59px;
      }
    }
  }

  .bg-blue-fullwidth {
    background-color: #2b3e66 !important;
    display: flex;
    justify-content: center;

    width: 100%;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      max-width: 100%;

      .container {
        padding-left: 50px !important;
        padding-right: 50px !important;
        max-width: 100%;
      }


    }

    .row {
      &.no-gutters {
        max-width: 1140px;
        color: white;
        width: 100%;
        padding-left: 25px !important;
        padding-right: 25px !important;

        @media (min-width: 768px) {
          padding-left: 50px !important;
          padding-right: 50px !important;
        }
      }
    }

    a {
      color: white;
    }
  }

  .bg-blue-dark {
    margin-bottom: 50px;
    max-width: 1140px;
    background-color: #2b3e66 !important;
    display: flex;
    justify-content: center;

    .container-special {
      padding: 25px 25px;

      @media (min-width: 768px) {
        padding: 40px 50px !important;
      }

    }

    h2, .h2 {
      font-size: 28px;
      color: white !important;

      .h3 {
        font-size: 28px;
        font-weight: bold;
        color: white;
      }
    }

    .row {
      &.no-gutters {
        max-width: 1140px;
        color: white;

      }
    }
  }

  .bg-blue-light {

    a, h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
      color: #294065 !important;

      strong {
        color: #294065 !important;
      }
    }

    display: flex;
    justify-content: center;

    .row.no-gutters {
      background-color: #ebf6f8 !important;
      display: flex;
      justify-content: center;
      max-width: 1080px;
      padding-left: 25px !important;
      padding-right: 25px !important;
      position: relative;

      @media (min-width: 768px) {
        padding-left: 50px !important;
        padding-right: 50px !important;
      }
    }

    h2 {
      font-size: 38px;
      font-weight: 700 !important;
    }

    p, h2 {
      color: #294065;
    }

    .row {
      &.no-gutters {
        max-width: 1140px;
        color: white;
        width: 100%;
      }
    }
  }

  .bg-transparent {
    color: #294065;
    max-width: 1140px;
    width: 100%;


    .row.no-gutters {
      display: flex;
      justify-content: center;
      max-width: 1080px;
      position: relative;
      padding-left: 0 !important;
      padding-right: 0 !important;

      @media (min-width: 1200px) {
        padding-left: 75px !important;
        padding-right: 75px !important;
      }
    }


  }

  .wrapper-section {
    &.svg-angle-left {
      background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/lab22-bg-oblique-up-left.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }
  }

  .wrapper-section {
    &.svg-angle-right {
      background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/lab22-bg-oblique-up-steep-right.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }
  }

  .contact {
    max-width: 1140px;
    color: white;

    .col-md-6 {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
      @media only screen and (min-width: 767px) {
        padding-left: 0;
      }
    }

    p {
      color: white;
      font-size: 20px;

    }
  }

  .links {
    p {
      width: 50%;
      border: 1px solid #2b3e66;
      padding: 8px;
    }

    a {
      color: #2b3e66;
    }
  }

  .row.card {

    .col-md-4 {
      padding-left: 0;
    }


    .col-md-4.text-white .row.no-gutters .col.textpicBodyTextCenter {
      border-bottom: 2px solid white;
      margin-bottom: 30px;
      padding-bottom: 50px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 768px) {
      .col-md-4.text-white .row.no-gutters .col.textpicBodyTextCenter {
        border-bottom: 0;
      }
      .col-md-4.text-white .row.no-gutters:nth-child(2n + 1) .col.textpicBodyTextCenter {
        border-bottom: 2px solid white;
      }
    }

    width: auto;
    display: flex;
    flex-direction: inherit;

    background: #294065;
    color: white;
    border: none;
    margin: 0;
    justify-content: space-between;

    .row.justify-content-center.mb-1 {
      margin: 0;
    }

    @media (min-width: 850px) {
      flex-direction: initial !important;
      //justify-content: space-evenly;
      max-width: 1080px;
      margin: 0;
      padding: 0;
    }

    .row {
      margin-bottom: 0 !important;
    }

    .row.no-gutters {
      // min-height: 225px;
    }

    .col-md-4 {
      //max-width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin: 0 !important;
      @media (min-width: 850px) {
        max-width: 33%;
      }
    }

    .col {

      &.textpicBodyTextCenter {
        color: white;
        padding-right: 15px;
        padding-top: 0;

        p {
          line-height: 1.3;
        }

        h3, .h2, p {

        }
      }
    }

    .magnific-gallery {
      .image {
        width: 80px;
      }

      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1000;
    }
  }

  form#lab22-1635 {
    h2 {
      display: none;
    }
  }

  .form-group {
    margin-top: 0;
    margin-bottom: 1.3rem;
  }

  .footer {
    background-image: none !important;

    button {
      @media (min-width: 992px) {

      }
      background: transparent !important;
      border: 1px solid white !important;
      color: white !important;
      font-weight: bold;
      font-size: 20px;
      margin-top: 25px;
      transition: background 250ms;

      &:hover {
        background: white !important;
        color: #2b3e66 !important;
      }
    }
  }

  .link-group {
    gap: 15px;
    display: flex;
    flex-direction: column;

    &-left, &-right {
      display: flex;
      gap: 15px;
    }

    a {
      &:after {
        background-image: url("/typo3conf/ext/myprovider/Resources/Public/sitecontrols/pdf.svg");
        content: "";
        background-repeat: no-repeat;
        background-color: #2b3e66;
        padding: 20px;
        background-size: 31px;
        display: flex;
        align-items: initial;
        background-position: center;
      }

      width: 200px;
      display: flex;
      color: #294065;
      border: 1px solid #294065;
      transition: background 250ms;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;

      &:hover {
        background: white;
        text-decoration: none;

      }
    }
  }

  .ce-uploads {
    gap: 15px;
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 100px;

    span {
      margin-right: 10px !important;
    }

    li {
      display: flex;
      gap: 15px;

      &:before {
        display: none;
      }

      a {
        min-width: 200px;

        &:after {
          background-image: url("/typo3conf/ext/myprovider/Resources/Public/sitecontrols/pdf.svg");
          content: "";
          background-repeat: no-repeat;
          background-color: #2b3e66;
          padding: 20px;
          background-size: 31px;
          display: flex;
          align-items: initial;
          background-position: center;

        }

        width: fit-content;
        display: flex;
        color: #294065;
        border: 1px solid #294065;
        transition: background 250ms;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;

        &:hover {
          background: white;
          text-decoration: none;

        }
      }

    }


  }

  .textcard {
    @media (min-width: 768px) {
      .col-md-6:last-child {
        padding-left: 30px;
      }
    }
  }

  .table td, .table th {
    border-top: 1px solid #294065 !important;
  }

  .table thead th {
    border-bottom: 1px solid #294065 !important;
  }

  .ce-table {
    color: #294065;
    margin-bottom: 50px;


    thead {
      th, td {
        color: #294065;
      }
    }

    tr td:nth-child(even) {
      background: white !important;
    }
  }

  .panel-group {
    a {
      color: #294065 !important;
      border-bottom: 1px solid #294065;
      padding: 5px 0 10px 0;
      display: inherit;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      font-size: 16px;
      color: #294065 !important;
      font-weight: 400;
      padding: 10px;
    }

    .panel-body {
      a {
        display: none !important;
      }
    }
  }

  .myprovider_fourfourfourcolumn {
    h2, .h3 {
      color: white !important;
    }
  }

  .mono_hubsecond_header__logo {
    img {
      width: 250px;
      margin-top: 175px;

      @media (min-width: 450px) {
        margin-top: 250px;
      }

      @media (min-width: 981px) {
        width: auto;
        margin-top: 0;
        max-width: 300px;
      }
    }
  }


  .hexagon {
    .row {
      &.no-gutters {
        background-repeat: no-repeat;
        background-size: contain !important;
      }
    }
  }

  .lab22-container {
    position: relative;
    max-width: 1080px;
    width: 100%;
    padding-left: 50px !important;
    padding-right: 50px !important;

    .mono_bdc_header__wrapper {
      margin-left: 5px;
    }
  }

  .mono_labsecond {
    &_header__text {
      margin-bottom: 50px;
      color: #fff;
      padding: 70px 60px 25px 25px;

      position: relative;
      left: -25px;
      margin-top: 32px;
      background: linear-gradient(215deg, transparent 75px, #2b3e66 0) top right;
      line-height: 1.3;
      max-width: 500px;

      @media (min-width: 981px) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 50px 130px 25px 25px;
      }

      .formatted-link {
        color: #2b3e66 !important;

        &:hover {
          color: white !important;
          background: #a4d8e0;
        }
      }

    }
  }

  .mono_lab_header {

    background-size: 60%;
    background-repeat: no-repeat;
    background-position-x: 100% !important;
    background-position-y: 50px !important;
    position: relative;
    z-index: 0;
    height: fit-content;
    align-content: center;
    display: flex;
    align-items: flex-start;

    background-color: white;
    background-position: top;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;

    @media (min-width: 768px) {
      background-position-y: -25px !important;
    }

    @media (min-width: 981px) {
      background-position-y: -150px !important;
      max-height: 850px;
      height: 100%;
    }

    @media (min-width: 1100px) {
      background-size: 69%;
    }

    @media (min-width: 1350px) {
      max-height: 1050px;
      background-size: 66%;
    }

    @media (min-width: 1550px) {
      max-height: 1200px;
    }

    @media (max-width: 980px) {
      padding-bottom: 0;
    }

    @media (min-width: 1100px) {
      align-content: center;
      margin-bottom: 0;
    }

    .formatted-link {
      padding: 10px;
      color: #586c7d !important;
      border: 2px solid #586c7d;
    }

    &__text {
      p {
        font-size: 18px !important;
        font-weight: 500;
      }

      color: #294066;

      a {
        font-size: 20px;
      }

      .formatted-link {
        background-color: transparent;
        border: 2px solid black;
        color: black !important;
        font-size: 25px;

        &:hover {
          background: black;
          color: white !important
        }
      }
    }

    &__wrapper {
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fffc;
      border: 2px solid;
      padding: 10px;
      width: 80%;

      @media only screen and (min-width: 1100px) {
        margin-left: 3%;
        width: 50%;
        display: flex;
        border: none;
        background: transparent;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__headline {


      max-width: 500px;

      p {
        color: #fff;
        position: relative;
        left: -25px;
        margin-top: 32px;
        line-height: 1.3;
        font-weight: bold;

      }

      @media only screen and (min-width: 1100px) {
        width: 90%;
        p {
          margin-top: 51px;
          font-size: 40px;
          color: #2b3e66;
          background: none;
        }
      }
      width: 100%;
      font-size: 40px;
      color: black;
    }

    &__container {
      @media (min-width: 1100px) {
        width: 80%;
      }
      width: 100%;
    }

    &__logo {
      height: fit-content;
      width: 100%;
      max-width: 200px;
      display: flex;
      justify-content: start;
      margin-bottom: 40px;
      margin-top: 0;
      @media (max-width: 850px) {
        width: 80%;
      }

      @media (min-width: 850px) {
        margin-top: 50px;
      }


      img {
        padding: 20px;
        padding-left: 0;
        @media (min-width: 850px) {
          max-width: 200px;
          padding: 0;
        }
      }
    }

  }

  .btn.btn-success.cookie-button-enable {
    background: #2b3e66 linear-gradient(180deg, #3e578a, #293f6f) repeat-x !important;
    border-color: #2b3e66 !important;

    &:hover {
      color: #2b3e66;
      background: #2b3e66 linear-gradient(180deg, #a4d8e0, #a4d8e0) repeat-x !important;
      border-color: #a4d8e0 !important;
    }
  }
}

body.tp-40 {

  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-family: Rubik, Arial, sans-serif;
  }

  .container {
    &-fluid {
      max-width: 1680px;
    }
  }

  .svg-angle-right {
    p {
      color: #0e497a;
      font-size: 15px;
    }
  }

  .bg-white.svg-angle-right {
    .container {
      max-width: 1000px;

      @media only screen and (min-width: 1000px) {
        padding: 0 !important;
      }
    }

    header {
      width: 100%;

      h1 {
        font-size: 53px;
        color: #0e497a;
        font-weight: 700;
      }
    }
  }

  .usps {
    .contenttable {
      display: flex;
      justify-content: center;
    }
  }

  .usps .text-white .textpicBodyTextCenter {
    h4, .h4, h5, .h5 {
      font-size: 16px;
      font-weight: 700;
      color: white;
      padding-bottom: 10px;
    }
  }

  .mono_tp-40_header {
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    justify-content: center;
    margin-bottom: 40px;
    @media (min-width: 600px) {
      justify-content: start;
      height: 100vh;
    }

    &__text {
      background: white;
      color: #0e497a;
      padding: 5px 10px;

      p {
        margin: 0;
        font-size: 32px;
      }
    }

    .formatted-link {
      padding: 10px;
      color: #586c7d !important;
      border: 2px solid #586c7d;
    }

    &__wrapper {
      background: #fffc;
      color: white;
      border: 2px solid;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 600px) {
        align-items: flex-start;
        background: none;
        border: none;
      }
    }

    &__headline {
      width: 100%;
      padding: 30px 25px;
      @media only screen and (min-width: 600px) {
        width: 1px;
        padding: 0;
        margin-top: 25px;
      }

      p {
        font-size: 70px;
        font-weight: bold;
        line-height: 1.05;
        @media only screen and (max-width: 1000px) {

        }
      }
    }

    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (min-width: 600px) {
        width: 100%;
        margin-top: 70px;
        justify-content: end;
      }
    }

  }

  .bg-blue-dark {
    margin-bottom: 85px;
    max-width: 1080px;
    background-color: #0e497a !important;
    display: flex;
    justify-content: center;

    .container-special {
      padding: 25px 25px;

      @media (min-width: 768px) {
        padding: 40px 50px;
      }

    }

    h2, .h2 {
      font-size: 40px !important;
      font-weight: bold;
      line-height: 1.1;
      color: white !important;
      max-width: 900px;
      letter-spacing: -1.3px;

      @media (min-width: 768px) {
        font-size: 63px;
      }


    }

    h3, .h3 {
      font-size: 28px;
      font-weight: bold;
      color: white !important;
    }

    .row {
      &.no-gutters {
        max-width: 1140px;
        color: white;

      }
    }

    &.svg-image-hide {
      .container-special {
        padding: 0 32px 20px 32px !important;
      }

      header {
        padding-top: 40px;
      }
    }
  }

  .bg-white {

    header {
      max-width: 1000px;
      width: 100%;

      h1 {
        font-size: 53px;
        display: flex;
        flex-direction: column;
        gap: 8px;

      }

      h1, span {
        color: #0e497a;
      }

      span {
        font-weight: 400;
        font-family: Rubik, Arial, sans-serif;
        font-size: 31px;
        letter-spacing: -0.3px;


      }
    }

    .col-md-12 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .container-special {
      padding: 0;
    }
  }

  .bg-red {
    background-color: #df6151 !important;
    display: flex;
    justify-content: center;

    .bg-blue-dark {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-start;

      h2 {
        font-size: 25px !important;
        letter-spacing: -1.5px;
      }

      .container-special {
        &:first-of-type {
          padding-bottom: 0;
        }

        padding-top: 0;
      }
    }

    .tp-40__card__text {
      background: transparent;
      max-width: unset;

      p {
        font-family: Rubik, Arial, sans-serif;
        font-size: 24px;
        line-height: 1.25;

        @media (min-width: 568px) {
          font-size: 36px;

        }
        @media (min-width: 768px) {
          font-size: 48px;
          letter-spacing: 1.3px;
        }

        padding: 0;
        width: fit-content;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: white;
        text-decoration-thickness: 5px;
      }
    }

    .tp-40__card-deck, .tp-40__card, .tp-40__card-body {
      width: 100%;
    }

    .tp-40__card__arrow {
      display: none;
    }

    .container-special {
      padding: 25px 25px;

      @media (min-width: 768px) {
        max-width: 1170px;
        padding-left: 70px !important;
        padding-right: 70px !important;
        padding-top: 95px !important;
        padding-bottom: 95px !important;
      }

    }

    h2, .h2 {
      font-size: 40px;
      color: white !important;
    }

    .h3 {
      font-weight: bold;
      color: white;

      max-width: 550px;
      font-size: 34px;
      letter-spacing: -0.6px;
    }

    .row {
      &.no-gutters {
        width: 100%;

        max-width: 1140px;
        color: #0e497a;

        .bg-blue-dark {
          p {
            color: white;
          }
        }
      }
    }
  }

  .row.card {

    header:first-of-type {
      display: none;
    }

    h2, h3 {
      display: flex;
      justify-content: center;
    }

    .col-md-4 {
      padding-left: 0;
    }

    .col-md-4.text-white .row.no-gutters .col.textpicBodyTextCenter {
      border-bottom: 2px solid white;
      margin-bottom: 30px;
      padding-bottom: 50px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 768px) {
      .col-md-4.text-white .row.no-gutters .col.textpicBodyTextCenter {
        border-bottom: 0;
      }
      .col-md-4.text-white .row.no-gutters:nth-child(2n + 1) .col.textpicBodyTextCenter {
        border-bottom: 2px solid white;
      }
    }


    tbody {
      font-weight: 300;
    }

    tr td {
      padding-left: 0;
    }

    width: auto;
    display: flex;
    flex-direction: inherit;
    border-radius: 0;
    background: #0e497a;
    color: white;
    border: none;
    margin: 0;
    justify-content: space-between;

    .row.justify-content-center.mb-1 {
      margin: 0;
    }

    @media (min-width: 850px) {
      flex-direction: initial !important;
      max-width: 1080px;
      margin: 0;
      padding: 0;
    }

    .row {
      margin-bottom: 0 !important;
    }

    .col-md-4 {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin: 0 !important;
      @media (min-width: 850px) {
        max-width: 25%;
      }
    }

    .col {

      &.textpicBodyTextCenter {
        color: white;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 0;

        p {
          line-height: 1.3;
        }
      }
    }

    .magnific-gallery {
      .image {
        width: 135px;
      }

      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1000;
    }
  }

  .wrapper-section {


    &.svg-angle-right {
      background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/tp40-svg-angle-right.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;

      @media only screen and (min-width: 1000px) {
        background-position: 0 -150px;
      }
    }


    &.svg-angle-left {
      background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/tp40-svg-angle-left.svg);
      background-repeat: no-repeat;
      background-position: 0 -4px;
      background-size: 350%;
    }
  }

  .tp-40 {

    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__header {
        color: #0e497a;
        position: relative;
        top: -10px;
        left: 12px;

        p {
          font-weight: bold;
          font-size: 14px;
          margin-top: 10px;
        }
      }

      &__subheader {
        color: #0e497a;
        font-size: 14px;
        max-width: 350px;
        top: -20px;
        position: relative;
        left: 8px;
        text-align: center;
      }

      &__image {
        width: 100%;
        height: 240px;
        max-width: 240px;
        border-radius: 100%;
        background-size: cover;
        position: relative;
        top: -30px;
      }

      &__text {
        width: 100%;
        max-width: 490px;
        background: #badfda;
        @media only screen and (min-width: 768px) {
          color: #0e497a;
          background: white;
        }


        p {
          padding: 25px 15px;
          font-weight: bold;
          font-size: 18px;
        }
      }

      &__arrow {
        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 0 solid transparent;
        border-top: 50px solid #badfda;

        @media only screen and (min-width: 768px) {
          border-top: 50px solid white;
        }
        left: 23%;
        position: relative;
      }

      &-testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-bottom {
          margin-top: 20px;
          width: 100%;

          display: flex;
          justify-content: center;
          flex-direction: column-reverse;
          align-items: center;
          gap: 25px;

          margin-bottom: 35px;

          @media only screen and (min-width: 768px) {
            max-width: 1112px;
            flex-direction: unset;
            margin-top: 0;
          }

          &-wrapper {
            display: flex;
            flex-direction: column;
            align-items: end;
          }

          .tp-40__card__header {
            top: 0;
            left: 0;
          }

          .tp-40__card__subheader {
            left: 0;
            max-width: unset;
          }

        }

        .tp-40__card {
          &__text {
            background: #d7ecea;

            &.red {
              background: #df6151;
            }

            &.blue {
              background: #0e497a;
            }

            &.light-blue {
              background: #d7ecea;

              p {
                color: #0e497a;
              }
            }


            @media only screen and (min-width: 768px) {
              max-width: 1112px;
              padding: 40px 60px 60px 60px;
            }

            p {
              padding: 10px;
              color: white;
              margin: 0;
              font-size: 24px;
            }
          }

          &__arrow {
            border-top: 50px solid #df6151;
            left: 40%;

            @media only screen and (min-width: 768px) {
              left: 57%;
            }

            &.red {
              border-top: 50px solid #df6151;
            }

            &.blue {
              border-top: 50px solid #0e497a;
            }

            &.light-blue {
              border-top: 50px solid #d7ecea;
            }
          }

          &__image {
            top: 0;
          }

          &-body {
            margin-bottom: 20px;
          }

          &__subheader {

            @media only screen and (min-width: 768px) {
              text-align: end;
            }
          }

          &__header {
            p {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  .testimonials {
    position: relative;
    margin-bottom: 30px;

    .testimonial__text p {
      color: #0e497a !important;
    }

    &.red {
      .testimonial__text p {
        color: #df6151 !important;
      }
    }
  }

  .testimonial {

    &__container {
      max-width: 1030px !important;
      margin-bottom: 95px;

      .col-md-4 {
        display: flex;
        flex-direction: column;
      }
    }

    &__image {
      width: 100%;
      height: 300px;
      background-size: cover;
      background-position: center;
    }

    &__header {
      p {
        color: #265b87;
        position: absolute;
        top: 33px;
        left: 34px;
        background: #fff;
        padding: 7px 11px 7px 11px;
        font-weight: 700;
        font-size: 14px;
        width: calc(100% - 99px);
      }
    }

    &__wrapper {
      height: 100%;
      padding-bottom: 15px;

      background: white;

      &-bottom {
        padding: 0 15px;
      }
    }

    &__name {
      p {
        font-weight: bold;
        margin: 0;
      }
    }

    &__title, &__name {
      p {
        color: #0e497a !important;
        font-size: 12px;
      }
    }

    &__link {
      padding: 0 15px;

      .bg-white.text-red-l.btn.btn-lg.btn-link {
        color: #df6151 !important;
        transition: transform 250ms;

        &:hover {
          color: #df6151 !important;
          transform: scale(1.1);
        }
      }

      &-icon {
        height: 30px;
        width: auto;
      }
    }

    &__text {
      width: 100%;

      p {
        padding: 25px 15px;
        padding-bottom: 0;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

}

/* fonts */

/* rubik-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* rubik-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-700.eot'); /* IE9 Compat Modes */
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/rubik/rubik-v28-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}


/* urbanist-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-300.eot'); /* IE9 Compat Modes */
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-300.svg#Urbanist') format('svg'); /* Legacy iOS */
}

/* urbanist-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-regular.svg#Urbanist') format('svg'); /* Legacy iOS */
}

/* urbanist-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-500.eot'); /* IE9 Compat Modes */
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-500.svg#Urbanist') format('svg'); /* Legacy iOS */
}

/* urbanist-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/typo3conf/ext/myprovider/Resources/Public/2017relaunch/docs/assets/fonts/urbanist/urbanist-v10-latin-700.svg#Urbanist') format('svg'); /* Legacy iOS */
}


