.wrapper-section-case-1 {
  color: $grey-dark;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: $blue-dark;

	strong {
	  color: $blue-dark;
	}

	br {
	  strong {
		color: $blue-dark;
	  }
	}
  }

  .formatted-link, .btn-link {
	color: $brand-primary;
	border: 1px solid $brand-primary;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
	line-height: 24px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;

	&:hover {
	  color: $white;
	  background-color: $brand-primary;
	}
  }

  .formatted-link {
	&.svg-image-show {
	  background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/struktur-back-hellrot.svg);
	  background-repeat: no-repeat;
	  background-position: 0 5%;
	  /* background-size: 60%; */
	}
  }

  figure {
	a {
	  border: none;
	}
  }

  .downloadsSidebarContent {
	a {
	  color: $brand-primary;
	}
  }

  a {
	color: $grey-dark;

	&:not(.formatted-link) {
	  &:hover span {
		text-decoration: underline;
	  }
	}
  }

  .bg-news-context-sensitive {
	background-color: $grey-light;
  }

  .event-wrapper {
	background-color: $white;
	transition: background-color .3s ease-in-out;

	&:hover {
	  background-color: $grey-light;
	}
  }

  .news-header {
	&:hover {
	  div[itemprop="description"] > p {
		color: $grey-dark;
	  }
	}
  }
}

.wrapper-section-case-2 {
  color: $white;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: $white;

	strong {
	  color: $white;
	}

	br {
	  strong {
		color: $white;
	  }
	}
  }

  .formatted-link, .btn-link {
	color: $white;
	border: 1px solid $white;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
	line-height: 24px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
  }

  &.svg-image-show {
	background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/struktur-back-hellrot.svg);
	background-repeat: no-repeat;
	background-position: 107% 130%;
	background-size: 50%;
  }

  .btn-primary {
	color: $white;
	border: 1px solid $white;
	background: transparent;
	&:hover {
	  background: $brand-sec;
	}
  }

  figure {
	a {
	  border: none;
	}
  }

  .downloadsSidebarContent {
	a {
	  color: $white;
	}
  }

  a {
	color: $white;

	&:not(.formatted-link) {
	  &:hover {
		text-decoration: underline;
	  }
	}

	&.formatted-link {
	  &:hover span {
		color: $blue-dark;
		background-color: $white;
	  }
	}
  }

  .bg-news-context-sensitive {
	background-color: $grey-light;
  }

  .event-wrapper {
	background-color: $white;
	transition: background-color .3s ease-in-out;

	&:hover {
	  background-color: $grey-light;
	}
  }

  .news-header {
	&:hover {
	  div[itemprop="description"] > p {
		color: $grey-dark;
	  }
	}
  }
}

.wrapper-section-case-3 {
  color: $white;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: $white;

	strong {
	  color: $white;
	}

	br {
	  strong {
		color: $white;
	  }
	}
  }

  .formatted-link, .btn-link {
	color: $white;
	border: 1px solid $white;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
	line-height: 24px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
  }

  &.svg-image-show {
	background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/struktur-back-hellrot.svg);
	background-repeat: no-repeat;
	background-position: 0 5%;
	background-size: 60%;
  }

  figure {
	a {
	  border: none;
	}
  }

  .downloadsSidebarContent {
	a {
	  color: $white;
	}
  }

  a {
	color: $white;

	&:not(.formatted-link) {
	  &:hover span {
		text-decoration: underline;
	  }
	}

	&.formatted-link {
	  &:hover {
		color: $brand-sec;
		background-color: $white;
	  }
	}
  }

  .bg-news-context-sensitive {
	background-color: $grey-light;
  }

  .event-wrapper {
	background-color: $white;
	transition: background-color .3s ease-in-out;

	&:hover {
	  background-color: $grey-light;
	}
  }

  .news-header {
	&:hover {
	  div[itemprop="description"] > p {
		color: $grey-dark;
	  }
	}
  }
}

.wrapper-section-case-4 {
  color: $grey-dark;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: $blue-dark;

	strong {
	  color: $blue-dark;
	}

	br {
	  strong {
		color: $blue-dark;
	  }
	}
  }

  .formatted-link, .btn-link {
	color: $brand-primary;
  }

  &.svg-image-show {
	background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/struktur-back-grau.svg);
	background-repeat: no-repeat;
	background-position: 0 5%;
	background-size: 60%;
  }

  figure {
	a {
	  border: none;
	}
  }

  .downloadsSidebarContent {
	a {
	  color: $brand-primary;
	}
  }

  a {
	color: $grey-dark;

	&:not(.formatted-link) {
	  &:hover span {
		text-decoration: underline;
	  }
	}

	&.formatted-link {
	  &:hover {
		color: $brand-primary;
		background-color: $white;
	  }
	}
  }

  .bg-news-context-sensitive {
	background-color: $white;
  }

  .event-wrapper {
	background-color: $grey-light;
	transition: background-color .3s ease-in-out;

	&:hover {
	  background-color: $white;
	}
  }

  .news-header {
	&:hover {
	  div[itemprop="description"] > p {
		color: $grey-dark;
	  }
	}

	.btn-link {
	  border: 1px solid $brand-primary;
	}
  }

}

.wrapper-section-case-1,
.wrapper-section-case-2,
.wrapper-section-case-3,
.wrapper-section-case-4 {
  ul {
	list-style-type: none;
	padding-left: 13px;
  }

  li:before {
	content: '-';
	padding-right: 7px;
	margin-left: -12px
  }

  .nav-tabs li:before {
	content: '';
	padding-right: 0;
	margin-left: 0;
  }
  &.svg-angle-top {
	background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/backangletop.svg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100%;
  }
  &.svg-angle-bottom {
	background-image: url(/typo3conf/ext/myprovider/Resources/Public/sitecontrols/backanglebottom.svg);
	background-repeat: no-repeat;
	background-position: 0 100%;
	background-size: 100%;
  }
}

.wrapper-section-case-4 {
  .formatted-link, .btn-link {
	color: $brand-primary;
	border: 1px solid $brand-primary;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 2px;
	padding-bottom: 2px;
	line-height: 24px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;

	&:hover {
	  color: $white;
	  background-color: $brand-primary;
	}
  }
}