.news {

  .news-list-view {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column !important;
      justify-content: stretch;
      align-content: stretch;
      align-items: stretch;
    }
  }

  .news-wrapper {
    background-color: #e4e4e5;
    width: 100%;
    overflow: hidden;
    min-height: 300px;
    .news-image {
      background-color: #e4e4e5;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .noTopNews {
    display: flex;
    width: 100%;

    @media(max-width: 991px) {
      display: block;

      &:first-child {
        //margin-right: 15px;
        .news-header {
          //margin-right: 15px;
        }
      }
      &:last-child {
        margin-right: 0 !important;
        //margin-left: 15px;
        .news-header {
          //margin-left: 15px;
        }
      }

    }

    @media(min-width: 992px) {

      &:first-child {
        //margin-right: 15px;
        .news-header {
          //margin-right: 15px;
        }
      }
      &:last-child {
        margin-right: 0 !important;
        //margin-left: 15px;
        .news-header {
          //margin-left: 15px;
        }
      }

    }

  }
  .mediaelement {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .news-header {
    font-size: 16px;
    overflow: hidden;
    bottom: -1px;
    top: auto;
    position: absolute;
    //bottom: 5px;
    left: 0;
    right: 0;
    height: auto;
    min-height: 0;

    .content-news {
      padding-top: 10px;
    }

    @include transitioning(200ms);

    a {
      font-size: 16px;
    }
    &:hover {

      min-height: 10vh !important;
      @media(min-width: 601px) {
        min-height: 19vh !important;
      }
      @media(min-width: 1200px) {
        min-height: 21vh !important;
      }
      @include transitioning(300ms);

      .content-news {
        padding-top: 0;
      }
    }
    .btn {
      width: 25px;
      height: 25px;

      .svg-inline--fa {
        font-size: 13px;
        margin: 0 0 5px -2px;
      }
    }
    .title-news {
      p {
        margin: 0 0 5px 0;
      }
    }
    @media(max-width: 600px) {
      position: relative;
      // height: auto !important;
      &:hover {
        min-height: 240px !important;
      }
      .btn {
        .svg-inline--fa {
          margin: 0 0 5px -2px;
        }
      }

    }
  }
  .success-story-Items {
    .news-header {
      &:hover {
        min-height: 0 !important;
      }
    }
  }
/*
  .news-wrapper:hover {
    .news-header {
      @include transitioning(300ms);
      top: 0;
      bottom: auto;
      height: 100% !important;
    }
  }
*/
  .teaser-text {
    p {
      font-weight: 400;
    }
  }
}

.bg-brand-l .news .news-wrapper {
  background-color: #ffffff;

  .news-image {
    background-color: #ffffff;
  }
}

.news-single {
  .img-right {
    max-width: 300px;
    float: right;
    margin-left: 20px;
  }
  @media screen and (max-width: 600px) {
    .img-right {
      float: left;
      margin-left: 0;
      margin-right: auto;
    }
    .news-text-wrap {
      float: left;
    }
  }
}





.pagination, .f3-widget-paginator {
  display: flex;
  @include list-unstyled();
  @include border-radius();
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      @include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    z-index: 2;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}

.f3-widget-paginator {
  padding-left: 0 !important;
}

.f3-widget-paginator li,
.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;
  &:before {
    content: '' !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
  }

  @include hover-focus {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}

