#main_nav_main_container {
  .navbar {
	padding: 0;
  }
  .navbar-language {
	a.active {
	  font-weight: bold;
	}
  }
  .carretIndicator {
	display: none;
  }
  font-size: 15px;
  @media (min-width: 992px) and (max-width: 1199px) {
	font-size: 15px;
  }
  a {
	color: $grey-dark;
  }
  .img-navi {
	@media (max-width: 992px) {
	  display: none;
	}
  }
  .navbar-brand {
	padding: 0;
  }
  #logoBrand {
	width: 200px;
	transition: width .4s;
	//margin-top: -17px;
	height: auto;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: transform;
	transition-property: transform;
	&:hover, &:focus, &:active {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);
	}

	@media (max-width: 1199px) {
	  width: 220px;
	  position: absolute;
	  top: 10px;
	}
	@media (max-width: 992px) {
	  width: 200px;
	  margin-top: 0;
	}
  }
  &.nav-scrolled {
	-webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
	#nav_row_snd {
	  @media (min-width: 992px) {
		margin-top: 35px !important;
	  }
	}
	.navbar-brand {
	  @media (max-width: 992px) {
		margin-top: 0 !important;
		top: 5px;
		//position: absolute;
	  }
	}
	#logoBrand {
	  position: absolute;
	  top: 10px;
	  margin-top: 0;
	  @media (max-width: 992px) {
		width: 120px !important;
		margin-top: 0;
	  }
	}
	.main-nav-container {
	  top: 2px;
	}
	.navbar-main {
	  height: 70px;
	  transition: height .4s;
	  padding-bottom: 0 !important;
	  padding-top: 0 !important;
	  @media (max-width: 1280px) {
		//height: 92px;
	  }
	  @media (max-width: 992px) {
		height: 70px;
	  }
	  @media (max-width: 480px) {
		height: 70px;
	  }
	}
	.mega-menu {
	  @media (max-width: 992px) {
		margin-top: 210px !important;
	  }
	}
  }
  .navbar-main {
	height: 152px;
	transition: height .4s;
	@media (max-width: 1280px) {
	  height: 130px;
	}
	@media (max-width: 1006px) {
	  height: 130px;
	}
	@media (max-width: 992px) {
	  height: 92px;
	}
	@media (max-width: 480px) {
	  .navbar-text {
		padding-top: 0;
		padding-bottom: 0;
	  }
	}
	.main-nav-container {
	  position: absolute;
	  top: 0;
	  right: 0;
	  .navbar-nav {
		.dropdown {
		  &.nav-link {
			.dropdown-toggle {
			  &:after {
				display: none;
			  }
			}
			.mega-menu {
			  display: block !important;
			  visibility: hidden;
			  &.show {
				visibility: visible;
				display: block !important;
				@include transitioning(200ms);
				height: 300px !important;
				-webkit-box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.18);
				-moz-box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.18);
				box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.18);
			  }
			  height: 0 !important;
			  overflow: hidden;
			  position: fixed;
			  @media (max-width: 992px) {
				position: fixed;
				overflow: auto;
				height: 100vh !important;
				max-height: 100vh;
				top: 0 !important;
				margin-top: 0 !important;
				width: 100% !important;
				left: 0 !important;
			  }
			  .dropdown-item {
				&:hover, :focus {
				  background: $white;
				}
			  }
			}
			&.show {
			  .carretIndicator {
				display: block;
				position: absolute;
				left: 0;
				width: 100%;
				//height: 100%;
				&:before {
				  @media (max-width: 991px) {
					display: none;
				  }
				  -moz-filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, .2));
				  -webkit-filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, .2));
				  -o-filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, .2));
				  -ms-filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, .2));
				  /*filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, .5));*/
				  content: '';
				  position: absolute;
				  left: 50%;
				  transform: translate(-50%, 0);
				  width: 0;
				  height: 0;
				  border-left: 20px solid transparent;
				  border-right: 20px solid transparent;
				  border-bottom: 15px solid $white;
				  z-index: 1500;
				  top: -13px;
				}
			  }
			  .megamenu-content {
				padding-top: 10px;
				font-size: 15px;
			  }
			}
			&.chevron-navToggle .carretIndicator {
			  &:before {
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 9px solid #fff;
				top: -7px;
			  }
			}
			@media (max-width: 992px) {
			  border-color: transparent !important;
			}
		  }
		}
	  }
	  @media (max-width: 992px) {
		padding: 20px !important;
		position: fixed;
		top: 25px;
		height: auto;
		overflow-y: auto;
		right: auto;
		z-index: 100000;
		background-color: #ffffff;
		width: 260px;
		left: auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.38);
		-moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.38);
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.38);
	  }
	  .navlink-mobil svg {
		color: $brand;
	  }
	  #nav_row_first {
		font-size: 12px;
		margin-top: 18px;
		@media (max-width: 992px) {
		  margin-bottom: 20px;
		}
		.input-group {
		  @media (max-width: 992px) {
			margin-top: 5px;
		  }
		}
		.form-control {
		  margin-left: 25px;
		  background-clip: unset;
		  @media (max-width: 992px) {
			margin-left: 10px;
		  }
		}
		form {
		  float: right;
		}
	  }
	}
	.form-control {
	  height: 25px;
	  font-size: 12px;
	  border-radius: 0;
	  border-width: 2px 0px 2px 2px;
	  border-style: solid solid solid solid;
	  border-color: $brand-gray-l $brand-gray-l $brand-gray-l $brand-gray-l;
	  &:focus, :hover, :active {
		border-width: 2px 0px 2px 2px;
		border-style: solid solid solid solid;
		border-color: $brand-gray-l $brand-gray-l $brand-gray-l $brand-gray-l;
		outline: 0;
		box-shadow: none;
		& ~ .input-group-append {
		  button {
			border-width: 2px 2px 2px 0;
			border-style: solid solid solid solid;
			border-color: $brand-gray-l $brand-gray-l $brand-gray-l $brand-gray-l;
		  }
		}
	  }
	}
	.btn-search {
	  border-width: 2px 0px 2px 2px;
	  border-style: solid solid solid solid;
	  border-color: $brand-gray-l $brand-gray-l $brand-gray-l $brand-gray-l;
	  height: 25px;
	  padding: 3px;
	  font-size: 18px;
	  line-height: 18px;
	  margin: 0;
	}
  }
  #nav_row_snd {
	transition: margin .4s;
	@media (min-width: 992px) {
	  margin-top: 40px !important;
	}
  }
  @media (width: 992px) {
	.row.no-gutters {
	  margin-top: 0 !important;
	  margin-bottom: 0 !important;
	}
	.megamenu-content {
	  padding-top: 120px !important;
	}
  }
  .mainmenudropdown {
	border-top: 0;
	border-bottom: 0;
	&.nav-link {
	  padding: 0 10px !important;
	  height: 47px;
	  @media (min-width: 992px) {
		height: 68px;
	  }
	  overflow: hidden;
	}
	&.chevron-navToggle {
	  height: 34px;
	}
  }
}

.max-width-menu {
  max-width: 300px;
}

.navbar-toggler {
  border-radius: 0;
  position: absolute;
  top: 46%;
  right: 0;
  transform: translateY(-46%);
  border-color: transparent !important;
  outline: none !important;
  height: 35px;
  width: 35px;
}

.seperator-megamenu {
  @media (max-width: 992px) {
	display: none;
  }
}

#search_icon_2 {
  padding: 0;
  @media (max-width: 992px) {
	display: none !important;
  }
  .btn-search {
	border: 0 !important;
	padding: 3px 6px 3px 3px
  }
  input {
	width: 0;
	-webkit-transition-property: width, opacity;
	-webkit-transition-duration: 0.5s;
	transition-property: width, opacity;
	transition-duration: 0.5s;
	padding: 0;
	background: #fff;
	border: 2px solid white !important;
	&:focus {
	  // border-color: $brand-gray-l $brand-gray-l $brand-gray-l $brand-gray-l !important;
	  border: 2px solid white !important;
	  & ~ .input-group-append {
		button {
		  // border-width: 2px 2px 2px 0;
		  border-width: 0;
		  // border-style: solid solid solid solid;
		  border-style: solid;
		  // border-color: $brand-gray-l $brand-gray-l $brand-gray-l $brand-gray-l !important;
		  border-color: #fff !important;
		}
	  }
	}
  }
  .form-control {
	opacity: 0;
  }
  &:hover {
	input {
	  width: 130px;
	  padding-left: 3px;
	  background: $brand-gray-l;
	}
	.form-control {
	  opacity: 1;
	}
  }
  &.chevron-navToggle {
	height: 27px;
  }
  .form-inline {
	margin: 0;
  }
}

.btnCloseMenuMobil {
  position: fixed;
  right: 0;
}

.menu-link-hyphens {
  @media (max-width: 992px) {
	margin-left: 4px !important;
	line-height: 5vw;
  }
  @media (max-width: 908px) {
	line-height: 7vw;
  }
  @media (max-width: 480px) {
	line-height: 10vw;
  }
}

.mobildropdown {
  -webkit-padding-start: 0px;
  padding-start: 0;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-left: 3px;
  a {
	cursor: pointer;
  }
  .nav-link {
	list-style: none;
	margin-left: 10px !important;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	border-top: 1px solid rgba(176, 46, 31, 0.2);
	color: rgba(176, 46, 31, 0.2);
	.active {
	  color: $brand-primary !important;
	}
	&:hover {
	  a {
		color: $brand-primary !important;
	  }
	}
  }
  ul {
	-webkit-padding-start: 0;
	padding-start: 0;
	li {
	  a {
		margin-left: 0 !important;
		padding-top: 0 !important;
		border-top: 1px solid $brand;
		color: $brand !important;
	  }
	}
	color: $brand;
	-webkit-padding-start: 20px;
	padding-start: 20px;
  }
}

.navlink-mobil {
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid $grey-dark;
  &:first-child {
	border-top: 1px solid $grey-dark;
  }
  color: $grey-light;
  &.active {
	svg {
	  color: $brand-sec !important;
	}
  }
  &.open {
	svg {
	  transform: rotate(90deg);
	  margin-left: -1px;
	  margin-right: 1px;
	}
  }
}

.navbar-text-mobil {
  margin-top: 10px !important;
  font-size: 10px;
  a {
	font-size: 11px;
	padding: 0;
	text-decoration: underline;
  }
}

.mobil-language {
  span {
	color: $brand;
	cursor: pointer;
	font-size: 12px;
	padding: 2px 6px;
	margin: 0 5px;
	a {
	  color: $brand !important;
	}
	&:first-child {
	  margin-left: 0;
	}
	border: 1px solid $brand;
	&.active {
	  font-weight: 700;
	  color: $brand;
	  a {
		color: $brand;
	  }
	}
  }
}

#menu-burger {
  width: 30px;
  height: 30px;
  border-radius: 0;
  position: absolute;
  background: $white;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 3594;
  .menu-icon {
	display: block;
	height: 2px;
	width: 30px;
	position: absolute;
	background: $brand;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-left: 0;
	margin-top: 0;
	&:after {
	  content: '';
	  display: block;
	  height: 2px;
	  width: 30px;
	  background: $brand;
	  position: absolute;
	  top: 50%;
	  left: 0;
	  margin-left: 0;
	  margin-top: -5px;
	  transition: .2s;
	}
	&:before {
	  content: ' ';
	  display: block;
	  height: 2px;
	  width: 30px;
	  background: $brand;
	  position: absolute;
	  top: 0;
	  left: 0;
	  margin-left: 0;
	  margin-top: 4px;
	  transition: .2s;
	}
  }
  &.open {
	.menu-icon {
	  background: transparent;
	  &:after {
		transform: rotate(-45deg);
		margin-top: -2px;
		background: $brand;
	  }
	  &:before {
		transform: rotate(45deg);
		margin-top: -1px;
		background: $brand;
	  }
	}
  }
}

.navi-chevi {
  vertical-align: top;
  padding: 1px 0 0 0 !important;
}

